import { WORKING_HOURS_EXT } from '@lib/miscellaneous';

import classes from './ContactsInfo.module.scss';

const phone = '+380 63 999 99 11';

interface IProps {
  withoutTitle?: boolean;
}

const ContactsInfo = ({ withoutTitle }: IProps) => (
  <div className={classes.contactsContainer}>
    {!withoutTitle ? <h3 className={classes.title}>Контакти</h3> : null}
    <div className={classes.phoneContainer}>
      <span className={classes.phoneNumber}>
        <a href={`tel:${phone}`}>{phone}</a>
      </span>
    </div>
    <div className={classes.workingHours}>
      <p>Час роботи:</p>
      <p>{WORKING_HOURS_EXT}</p>
    </div>
  </div>
);

export { ContactsInfo };
