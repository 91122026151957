import { useState } from 'react';
import { Drawer } from '@mui/material';

import { Icon, IconButton, Search } from '@components';

import classes from './HeaderSearchMobile.module.scss';

const HeaderSearchMobile = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen((state) => !state);

  return (
    <div className={classes.searchBlock}>
      <IconButton onClick={toggleDrawer} className={classes.searchBtn} ariaLabel="Пошук">
        <Icon type="search" size={24} />
      </IconButton>

      <Drawer
        open={drawerOpen}
        anchor="top"
        classes={{
          paper: classes.mobileDrawer,
          root: classes.drawerRoot
        }}
      >
        <Search onClose={toggleDrawer} />
      </Drawer>
    </div>
  );
};

export { HeaderSearchMobile };
