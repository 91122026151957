import { FC, useState } from 'react';
import clsx from 'clsx';

import { MainLogoLink, PlacesAutocompleteModal } from '@components';
import { SocialLinks } from '@components/shared';
import PhoneLink from '@components/shared/PhoneLink/PhoneLink';
import { HeaderSearchDesktop } from '@layout/header-main/search/HeaderSearchDesktop';
// import NotificationHeaderTop from '@layout/header/notification/NotificationHeaderTop';
import WebsiteLinks from '@layout/header/website-links/WebsiteLinks';
import AccountLinks from './account-links/AccountLinks';

import classes from './HeaderTop.module.scss';

interface IHeaderTopProps {
  isScrolled?: boolean;
}

export const HeaderTop: FC<IHeaderTopProps> = ({ isScrolled }) => {
  const [searchOpen, setSearchOpen] = useState(false);

  function closeSearch() {
    setSearchOpen(false);
  }

  function openSearch() {
    setSearchOpen(true);
  }

  return (
    <>
      {/*<ClientOnly>*/}
      {/*  <NotificationHeaderTop />*/}
      {/*</ClientOnly>*/}
      <div className={classes.headerWrapper}>
        <div className={clsx(classes.gridItem, classes.infoWrapper, { [classes.hideGridItem]: searchOpen })}>
          <div className={classes.headerInfo}>
            <PlacesAutocompleteModal />
            <div className={classes.companyInfo}>
              <SocialLinks />
              <PhoneLink />
            </div>
            <WebsiteLinks />
          </div>
        </div>
        <div className={clsx(classes.gridItem, classes.mainLogoWrapper, { [classes.hideGridItem]: searchOpen })}>
          <MainLogoLink height={36} />
        </div>
        <div className={clsx(classes.gridItem, classes.userSettingsWrapper)}>
          <span className={clsx(classes.currency, { [classes.hide]: searchOpen })}>Грн</span>
          <span className={clsx(classes.containerFlag, { [classes.hide]: searchOpen })}>UA</span>
          <HeaderSearchDesktop
            searchOpen={searchOpen}
            closeSearch={closeSearch}
            openSearch={openSearch}
            isScrolled={isScrolled}
            className={clsx(classes.search, { [classes.searchOpen]: searchOpen })}
          />
          {!searchOpen ? <AccountLinks /> : null}
        </div>
      </div>
    </>
  );
};
