'use client';

import { staticPages } from '@lib/constants/static-pages';
import { DesktopOnly } from '../desktop-only/DesktopOnly';
import { MobileOnly } from '../mobile-only/MobileOnly';
import { FooterSocialLinks } from './footer-social-links/FooterSocialLinks';
import { ContactsInfo } from './FooterContacts/ContactsInfo';
import FooterNavGroup, { FooterNavGroupAccordion } from './FooterNav/FooterNavGroup';
import NavDevice from './NavDevice/NavDevice';

import classes from './footer.module.scss';

const aboutUsLinks = [staticPages.aboutUs, staticPages.contacts, staticPages.shops];
const forClientLinks = [
  staticPages.delivery,
  staticPages.payment,
  staticPages.blog,
  staticPages.replacement,
  staticPages.publicOferta
];

const Footer = () => (
  <>
    <footer className={classes.footerWrapper}>
      <MobileOnly>
        <div className={classes.footerContainer}>
          <FooterSocialLinks logoHeight={26} logoWidth={145} />
          <FooterNavGroupAccordion title="Про компанію" links={aboutUsLinks} />
          <FooterNavGroupAccordion title="Клієнтам" links={forClientLinks} />
          <ContactsInfo withoutTitle />
        </div>
      </MobileOnly>
      <DesktopOnly>
        <div className={classes.footerContainer}>
          <FooterSocialLinks />
          <FooterNavGroup title="Про компанію" links={aboutUsLinks} />
          <FooterNavGroup title="Клієнтам" links={forClientLinks} />
          <ContactsInfo />
        </div>
      </DesktopOnly>
    </footer>
    <MobileOnly>
      <NavDevice />
    </MobileOnly>
  </>
);

export default Footer;
