import { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { Drawer } from '@mui/material';

import { AuthModal, Icon, MobileModalHeader } from '@components';
import { useCartData } from '@contexts/cart-data';
import { useMainMenu } from '@contexts/main-menu';
import { IMainMenuData } from '@types';
import { getRelAttribute } from '@utils/formatting';
import { More } from './More';

import classes from './NavDevice.module.scss';

interface IMenuItemProps {
  isScrolled: boolean;
  item: IMainMenuData;
}

const MenuItem: FC<IMenuItemProps> = ({ item, isScrolled }) => {
  const [isInnerMenuOpen, setIsInnerMenuOpen] = useState(false);
  // const showNotification =
  //   typeof window !== 'undefined' ? localStorage.getItem('doNotShowGlobalNotification') !== 'true' : false;

  function handleInnerMenu() {
    setIsInnerMenuOpen((s) => !s);
  }

  if (item.children?.length) {
    return (
      <>
        <li className={classes.menuItem} onClick={handleInnerMenu}>
          <div className={classes.itemImage}>
            <Image src={item.image || 'assets/images/flower-no-image.svg'} alt={item.name} fill />
          </div>
          <span>{item.name}</span>
        </li>
        <Drawer
          open={isInnerMenuOpen}
          anchor={'right'}
          onClose={handleInnerMenu}
          slotProps={{ backdrop: { invisible: true } }}
          classes={{
            paper: clsx(classes.innerMobileModal, {
              [classes.mobileModalTop]: isScrolled,
              // TODO fix logic for mobile notification
              // [classes.showNotification]: !showNotification,
              [classes.fullHeightDrawer]: isScrolled
            })
          }}
        >
          <MobileModalHeader onClose={handleInnerMenu} title={item.name} backButton />
          <nav>
            <ul className={classes.innerNavigationList}>
              {item.children.map((child) => (
                <li key={child.seoKey} className={classes.categorySubTitleLink}>
                  <Link href={child.seoKey} className={classes.itemContainer} {...getRelAttribute(child.seoKey)}>
                    <div className={classes.innerMenuImage}>
                      <Image fill src={child.image || 'assets/images/flower-no-image.svg'} alt={child.name} />
                    </div>
                    <p className={classes.childrenName}>{child.name}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <Link href={item.seoKey}>
            <span className={classes.viewAll}>Дивитись всі</span>
          </Link>
        </Drawer>
      </>
    );
  }

  return (
    <li className={classes.menuItem} onClick={handleInnerMenu}>
      <Link href={item.seoKey}>
        <div className={classes.itemImage}>
          <Image src={item.image || 'assets/images/flower-no-image.svg'} alt={item.name} fill />
        </div>
        <span>{item.name}</span>
      </Link>
    </li>
  );
};

const FooterNav = () => {
  const { data: session, status: sessionStatus } = useSession();
  const pathname = usePathname();
  const { mainMenu } = useMainMenu();
  const { cart } = useCartData();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  // const showNotification =
  //   typeof window !== 'undefined' ? localStorage.getItem('doNotShowGlobalNotification') !== 'true' : false;

  const totalInCart = cart?.length ? cart.reduce((acc, c) => c.qty + acc, 0) : 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleMenuOpen() {
    if (isMoreOpen) {
      setIsMoreOpen(false);
    }
    setIsMenuOpen((s) => !s);
  }

  function handleMoreOpen() {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    setIsMoreOpen((s) => !s);
  }

  function handleLinkClick() {
    setIsMenuOpen(false);
    setIsMoreOpen(false);
  }

  return (
    <>
      <div className={clsx(classes.root, classes.safeBottom, 'bottom', 'fullWidth')}>
        <div className={classes.container}>
          <div
            className={clsx(classes.link, { [classes.active]: isMenuOpen }, classes.profileItem)}
            onClick={handleMenuOpen}
          >
            <Icon type="catalog" size={24} className={classes.linkIconFill} />
            <span>Каталог</span>
          </div>
          <div className={classes.profileItem}>
            {sessionStatus === 'authenticated' ? (
              <Link
                href="/tp/profile/favorites"
                className={clsx(classes.link, {
                  [classes.active]: pathname === '/tp/profile/favorites' && !(isMenuOpen || isMoreOpen)
                })}
                onClick={handleLinkClick}
              >
                <Icon type="heart" size={24} className={classes.linkIcon} />
                <span>Обране</span>
                {session?.user?.watchList.length ? <span className={classes.favoritesIcon} /> : null}
              </Link>
            ) : (
              <AuthModal onWrapperClick={handleLinkClick}>
                <div className={classes.link}>
                  <Icon type="heart" size={24} className={classes.linkIcon} />
                  <span>Обране</span>
                </div>
              </AuthModal>
            )}
          </div>
          <div className={classes.profileItem}>
            <Link
              href="/tp/cart"
              className={clsx(classes.link, {
                [classes.active]: pathname === '/tp/cart' && !(isMenuOpen || isMoreOpen)
              })}
              onClick={handleLinkClick}
            >
              <Icon type="cart" size={24} className={classes.linkIcon} />
              <span color="inherit">Кошик</span>
              {cart.length ? <span className={classes.cartIconQty}>{totalInCart}</span> : null}
            </Link>
          </div>
          <div className={classes.profileItem}>
            {sessionStatus === 'authenticated' ? (
              <Link
                href="/tp/profile"
                className={clsx(classes.link, {
                  [classes.active]: pathname === '/tp/profile' && !(isMenuOpen || isMoreOpen)
                })}
                onClick={handleLinkClick}
              >
                <Icon type="profile" size={24} className={classes.linkIcon} />
                <span>{session?.user?.profile?.firstName ?? ''}</span>
              </Link>
            ) : (
              <AuthModal onWrapperClick={handleLinkClick}>
                <div className={classes.link}>
                  <Icon type="profile" size={24} className={classes.linkIcon} />
                  <span>Профіль</span>
                </div>
              </AuthModal>
            )}
          </div>
          <div
            className={clsx(classes.link, { [classes.active]: isMoreOpen }, classes.profileItem)}
            onClick={handleMoreOpen}
          >
            <Icon type="more" size={24} className={classes.linkIconFill} />
            <span>Ще</span>
          </div>
        </div>
      </div>
      <Drawer
        open={isMenuOpen}
        anchor={'bottom'}
        onClose={handleMenuOpen}
        slotProps={{ backdrop: { invisible: true } }}
        classes={{
          paper: clsx(classes.mobileModal, {
            [classes.mobileModalTop]: isScrolled,
            // TODO fix logic for mobile notification
            // [classes.showNotification]: !showNotification,
            [classes.fullHeightDrawer]: isScrolled
          }),
          root: classes.mobileModalRoot
        }}
      >
        <nav>
          <ul className={classes.navigationList}>
            {mainMenu ? mainMenu.map((item, idx) => <MenuItem key={idx} item={item} isScrolled={isScrolled} />) : null}
            {mainMenu ? (
              <li className={classes.menuItem}>
                <Link href="/sales" onClick={handleMenuOpen}>
                  <div className={classes.itemImage}>
                    <Image src="/assets/images/offers-banner-part-one.png" alt="" fill />
                  </div>
                  <span className={classes.offersTitle}>Акції</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </nav>
      </Drawer>
      <Drawer
        open={isMoreOpen}
        anchor={'bottom'}
        onClose={handleMoreOpen}
        slotProps={{ backdrop: { invisible: true } }}
        classes={{
          paper: clsx(classes.mobileModal, {
            [classes.mobileModalTop]: isScrolled,
            // TODO fix logic for mobile notification
            // [classes.showNotification]: !showNotification,
            [classes.fullHeightDrawer]: isScrolled
          }),
          root: classes.mobileModalRoot
        }}
      >
        <More />
      </Drawer>
    </>
  );
};

export default FooterNav;
