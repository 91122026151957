'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { MainLogoLink } from '@components';
// import NotificationHeaderTop from '@layout/header/notification/NotificationHeaderTop';
import PhoneLink from '../../components/shared/PhoneLink/PhoneLink';
import { DesktopOnly } from '../desktop-only/DesktopOnly';
import { HeaderTop } from '../header/HeaderTop';
import { MobileOnly } from '../mobile-only/MobileOnly';
import { HeaderNavigationMenu } from './HeaderNavigationMenu/HeaderNavigationMenu';
import { HeaderSearchMobile } from './search/HeaderSearchMobile';

import classes from './HeaderMain.module.scss';

const HeaderMobile = () => {
  return (
    <>
      {/*<ClientOnly>*/}
      {/*  <NotificationHeaderTop/>*/}
      {/*</ClientOnly>*/}
      <div className={classes.mobileHeader}>
        <MainLogoLink width={138} height={25} />
        <div className={classes.container}>
          <PhoneLink withPopup onlyIcon position="bottom right" />
          <HeaderSearchMobile />
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const headerTopRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const headerTopEl = headerTopRef.current;
    let observer: IntersectionObserver;
    if (headerTopEl) {
      observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          const isCurrentlyIntersecting = !entry.isIntersecting;
          if (isScrolled !== isCurrentlyIntersecting) {
            setIsScrolled(isCurrentlyIntersecting);
          }
        },
        { threshold: 0 }
      );

      observer.observe(headerTopEl);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (headerTopEl) {
        observer.unobserve(headerTopEl);
      }
    };
  }, []);

  return (
    <header className={clsx(classes.header, { [classes.stickyHeader]: isScrolled })}>
      <MobileOnly>
        <HeaderMobile />
      </MobileOnly>
      <DesktopOnly>
        <div className={classes.wrapper}>
          <div
            ref={headerTopRef}
            className={clsx({
              [classes.headerTopHidden]: isScrolled,
              [classes.headerTopVisible]: !isScrolled
            })}
          >
            {!isScrolled && <HeaderTop isScrolled={isScrolled} />}
          </div>
          <HeaderNavigationMenu isScrolled={isScrolled} />
        </div>
      </DesktopOnly>
    </header>
  );
};

export default Header;
